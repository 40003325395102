import { useStore } from "~/store";

export function useMetaTags(notaria:any,PageName:string,SubPage?:string)  {
  const store = useStore()
  const route = useRoute()
  const headers = useRequestHeaders()
  //const {notaria} = storeToRefs(store)
  
  useServerSeoMeta({
    robots: 'index, follow'
  })
  const PageNames:any ={
    Equipo:{propertyName:"Team"}
  }
  var ThemePropertyName:string
  if(PageNames[PageName]){
    ThemePropertyName=PageNames[PageName].propertyName
  }else{
    ThemePropertyName=PageName
  }
  const pageTheme = notaria.Theme[ThemePropertyName]
  console.log(pageTheme)
    var meta = [
        {name:"robots", content:"follow"},        
        
      ]
      if(pageTheme)
      {
        meta.push({ vmid: 'description', name: 'description', content: pageTheme.Description })
        meta.push({ name: 'keywords', content: pageTheme.Keywords })
      }
      //console.log(meta)
    if(notaria.Personalizar.GoogleSiteVerification){
      meta.push({ name: 'google-site-verification', content: notaria.Personalizar.GoogleSiteVerification })
    }      
    var metaTags= {
      
      titleTemplate: '%s | '+notaria.Nombre,
      htmlAttrs: {
        lang: notaria.Languaje
      },
      meta,
      link:[
        { rel: 'canonical', href: 'https://'+store.host+'/'+PageName+'/'+(SubPage?SubPage:'') }
      ]
    };
    if(pageTheme)
    {
      metaTags.title = pageTheme.Titulo.Texto
    }else{
      metaTags.title = PageName
    }

    useHead(metaTags)  
    useSeoMeta(metaTags)
    return{}
  }